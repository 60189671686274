.siteInspectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .infoSection {
    display: flex;
    gap: 30px; /* Space between Date and Created by */
  }
  
  .infoSection div {
    font-size: 16px;
    color: #333;
  }
  
  .infoSection strong {
    font-weight: 600;
    color: #555;
    margin-right: 5px;
  }
  
  .addInspectionButton {
    background-color: #1f5e6d;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .addInspectionButton:hover {
    background-color: #174950;
  }
  