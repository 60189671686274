.boqContainer {
  font-family: Arial, sans-serif;
  width: 78.5%;
  float: right;
  right: 50px;
  overflow-x: auto;
  position: absolute;
  top: 65px;
}

.boqHeading {
  font-size: 24px;
  text-align: left;
  margin-bottom: 20px;
  color: #333;
}

.boqPackageList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.boqPackageCard {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: #fff;
}

.boqPackageCard:hover {
  transform: scale(1.05);
}

.boqPackageCard.boqActive {
  border-color: #00796b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.boqPackageImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.boqPackageInfo {
  padding: 10px;
}

.boqPackageName {
  font-size: 16px;
  color: #333;
}

.boqPackagePrice {
  color: #00796b;
  font-weight: bold;
  margin-top: 5px;
}

.boqFormSection {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.boqInputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.boqInputGroup {
  flex: 1;
  margin-right: 15px;
}

.boqDynamicFields {
  display: flex;
}

.boqInputGroup:last-child {
  margin-right: 0;
}

label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input:focus {
  outline: none;
  border-color: #00796b;
}
