.csv-container {
  max-width: 800px;
  height: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.csv-title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.csv-upload {
  text-align: left;
  margin-bottom: 20px;
}

.upload-label {
  display: block;
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}

.file-input {
  display: block;
  margin: 0 auto;
}

.progress,
.error {
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
}

.error {
  color: red;
}

.csv-log {
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background: #f9f9f9;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.csv-log ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.log-success {
  color: #2d862d;
}

.log-error {
  color: #862d2d;
}

.csv-summary {
  background: #eef5f9;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
}

.csv-summary-box {
  display: flex;
  font-size: 13px;
  justify-content: space-between;
}

.highlight {
  font-weight: bold;
  color: #2d862d;
  font-size: 14px;
}
