.tableContainer {
  width: 100%;
  margin-top: 180px;
  padding: 10px 0px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  width: 78.5%;
  float: right;
  right: 50px;
  top: 65px;
  position: absolute;
}

.teamTable {
  width: 100%;
  border-collapse: collapse;
}

.tableContainer {
  margin-top: 0px !important;
  background-color: transparent !important;
  box-shadow: none;
  padding: 0px;
}

.teamTable th, .teamTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.teamTable th {
  background-color: #1F5E6C;
  color: white;
}

.teamTable td {
  color: #555;
}

.teamTable tr:hover {
  background-color: #f5f5f5;
}

/* Task cell with radio button and ellipsis */
.taskCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.radioButton {
  margin-right: 10px;
  cursor: pointer;
}

.taskName {
  flex-grow: 1;
  margin-left: 10px;
}

.iconEllipsis {
  margin-left: auto;
  color: #767676;
  cursor: pointer;
}

/* Popup for status update */
.statusPopup {
  position: absolute;
  left: 40px;
  top: 30px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
}

.statusPopup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.statusPopup ul li {
  padding: 8px;
  cursor: pointer;
}

.statusPopup ul li:hover {
  background-color: #f0f0f0;
}

/* Ellipsis Options */
.ellipsisMenu {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
}

.ellipsisMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ellipsisMenu ul li {
  padding: 8px;
  cursor: pointer;
}

.ellipsisMenu ul li:hover {
  background-color: #f0f0f0;
}

/* Input for renaming tasks */
.renameInput {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Positioning of the status cell */
.statusCell {
  position: relative;
}

.assigneeStack {
  display: flex;
  align-items: center;
  position: relative;
  gap: -10px; /* Negative gap for overlapping */
}

.assigneePicture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: -10px; /* Negative margin for overlapping */
  object-fit: cover;
  border: 2px solid #fff; /* White border to create a separation between images */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
  cursor: pointer;
  transition: transform 0.2s ease, border-color 0.3s ease;
}

.assigneePicture:hover {
  border-color: #007bff; /* Highlight border color on hover */
  transform: scale(1.1); /* Zoom effect on hover */
}



.statusPopup {
  position: absolute;
  left: 40px;
  top: 20px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
  width: 200px;
  border: 1px solid #ddd;
}

.statusPopup h4 {
  margin: 0 0 10px;
  font-size: 14px;
  color: #333;
}

.statusPopup .radioGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.statusPopup .radioGroup label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.statusPopup .radioGroup input[type='radio'] {
  margin-right: 8px;
  accent-color: #007bff; /* Radio button color */
}

.statusPopup .radioGroup input[type='radio']:checked + label {
  font-weight: bold;
  color: #007bff;
}

.highPriority {
  background-color: #e57373;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
}

.mediumPriority {
  background-color: #ffb74d;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
}

.lowPriority {
  background-color: #81c784;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
}

.notStarted {
  background-color: #f0f4c3;
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
}

.inProgress {
  background-color: #64b5f6;
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
}

.completed {
  background-color: #a5d6a7;
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
}
