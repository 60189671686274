.sidebarParent {
  width: 220px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 60px;
  padding-left: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}

.sidebarParent::-webkit-scrollbar {
  width: 6px;
}

.sidebarParent::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.groupHeader {
  font-weight: bold;
  margin: 10px 0 5px 15px;
  font-size: 14px;
  color: #4a4a4a;
}

.item {
  padding: 12px 20px 12px 15px;
  font-size: 15px;
  cursor: pointer;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.item:hover {
  background-color: #e0e0e0;
  color: #000;
}

.logoutItem {
  padding: 15px;
  font-size: 15px;
  cursor: pointer;
  color: #9e9e9e;
  display: flex;
  align-items: center;
}

.icon {
  padding-right: 15px;
  font-size: 18px;
}

.logout {
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #1f5e6d;
  background-color: #1f5e6d;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout:hover {
  background-color: #e0e0e0;
  color: #000;
}

.copyright {
  padding: 10px;
  font-size: 12px;
  color: #9e9e9e;
  text-align: left;
  width: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmButton {
  background-color: #1f5e6d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.confirmButton:hover, .cancelButton:hover {
  opacity: 0.8;
}
