
.container {
  padding: 20px;
  margin: 20px;
  float: right;
  width: 87.8%;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.costTable {
  width: 100%;
  border-collapse: collapse;
}

.costTable th, .costTable td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.submitButton {
  padding: 10px 20px;
  background-color: #1f5e6c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
}

.submitButton:hover {
  background-color: #174b55;
}

.addItemsButton {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
}

.addItemsButton:hover {
  background-color: #218838;
}

.checkModalItem {
  width: auto;
}

.greyInput {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
}

.costbreakupModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
}

.costbreakupModalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.costbreakupItemList {
  list-style: none;
  padding: 0;
}

.costbreakupItemRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.costbreakupCloseModal {
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.costbreakupModalContent input{
  width: 20%;
}

.costbreakupCloseModal:hover {
  background-color: #0056b3;
}
