.container {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.content {
  margin-left: 20px;
  flex-grow: 1;
}

.step {
  margin: 10px 0;
}

button {
  margin-top: 20px;
}
