.choosePkgItemContainer {
    display: flex;
    flex-direction: row;
    width: 84%;
    margin-top: 40px;
    margin-bottom: 100px;
    float: right;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
  }
  
  .choosePkgItemLeftSection {
    flex: 2;
    padding: 20px;
    overflow-y: auto;
  }
  
  .choosePkgItemBackButton {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .choosePkgItemBackButton svg {
    font-size: 1.2rem;
  }
  
  .choosePkgItemSelectedItemsList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    margin-top: 60px; /* Adjust to account for Back button */
  }
  
  .choosePkgItemSelectedItemCard {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    border: 1px solid #ddd;
    padding: 15px 60px;
    width: 82%;
    border-radius: 8px;
    background: white;
  }
  
  .choosePkgItemItemDetails {
    flex: 1;
  }
  
  .choosePkgItemItemDescription {
    width: 100%;
    height: 120px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-top: 5px;
  }
  
  .choosePkgItemTrashIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .choosePkgItemButtonGroup {
    position: fixed;
    bottom: 10px;
    width: 74.8%;
    border-radius: 10px;
    margin: auto;
    float: right;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .choosePkgItemAddItemButton {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  .choosePkgItemRemoveButton {
    padding: 2.5px;
  }

  .choosePkgItemTrashIcon {
    font-size: 13px;
  }
  
  .choosePkgItemRightButtons {
    display: flex;
    gap: 10px;
  }
  
  .choosePkgItemSaveDraftButton,
  .choosePkgItemViewPdfButton {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .choosePkgItemRightModalOverlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    z-index: 999;
    justify-content: flex-end;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  
  .choosePkgItemRightModalOverlay.choosePkgItemOpen {
    visibility: visible;
    opacity: 1;
  }
  
  .choosePkgItemRightModal {
    width: 400px;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    border-left: 2px solid #ddd;
    padding: 20px;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .choosePkgItemRightModal.choosePkgItemOpen {
    transform: translateX(0);
  }
  
  .choosePkgItemItemsList {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .choosePkgItemItemCard {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .choosePkgItemItemCard input[type="checkbox"] {
    transform: scale(1.2);
    width: 20%;
  }
  
  .choosePkgItemModalHeading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .choosePkgItemCloseButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .choosePkgItemNextButton {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
  }
  