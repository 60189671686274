.termsContainer {
    margin: 20px 0;
    padding: 15px;
    background: linear-gradient(135deg, #f9f9f9, #e8f4ff);
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .termsContainer h4 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }
  
  .termsContainer ul {
    margin: 0;
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .termsContainer li {
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
  }
  