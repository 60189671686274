.containerSiteIns {
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}

h2 {
    font-size: 20px;
    margin-bottom: 15px;
}

.questionGridSiteIns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 15px; /* Added row gap for better spacing */
    width: 96%; /* Reduced width for compactness */
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Reduced shadow intensity */
    padding: 15px; /* Reduced padding */
    border-radius: 8px; /* Reduced border radius */
}

.questionItemSiteIns {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Reduced gap between items */
    padding: 5px 0; /* Reduced padding */
    font-size: 14px; /* Smaller font size */
    font-weight: 300; /* Adjusted font weight for a cleaner look */
    color: black;
}

label {
    color: #000000;
    font-size: 14px; /* Reduced font size */
    font-weight: 300; /* Adjusted font weight */
    white-space: nowrap;
}

.inputsSiteIns {
    display: flex;
    gap: 8px; /* Reduced gap between inputs */
    width: 100%;
}

.inputSiteIns {
    padding: 6px; /* Reduced padding */
    border: 1px solid #ddd;
    border-radius: 4px; /* Reduced border radius */
    font-size: 12px; /* Smaller font size */
    flex: 1;
}

.saveButtonSiteIns {
    padding: 10px 20px; /* Reduced padding */
    margin-top: 15px; /* Reduced margin */
    color: white;
    background-color: #17494f;
    border: none;
    border-radius: 6px; /* Reduced border radius */
    font-size: 14px; /* Smaller font size */
    cursor: pointer;
    display: block;
    grid-column: span 2;
    justify-self: center;
}

@media (max-width: 768px) {
    .questionGridSiteIns {
        grid-template-columns: 1fr; /* Single column for smaller screens */
    }

    .saveButtonSiteIns {
        grid-column: span 1; /* Adjust button layout for smaller screens */
    }
}
