/* BoqOne Component Styles */
.form {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  color: #1f5e6c;
  margin-bottom: 30px;
}

.categories {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.box {
  flex: 1;
  padding: 12.5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.box:hover {
  background-color: #1f5e6c;
  color: #ffffff;
  transform: translateY(-5px);
}

.packages {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.packageBox {
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.packageBox:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.packageBox h3 {
  font-size: 16px;
  font-weight: 600;
  color: #1f5e6c;
}

.packageBox p {
  font-size: 14px;
  margin: 5px 0;
  color: #333;
}

.packageBox ul {
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: disc;
}

.packageBox ul li {
  font-size: 14px;
  color: #555;
}

.imageContainer {
  margin: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 200px;
  max-height: 300px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.button {
  padding: 12px 50px;
  background-color: #1f5e6c;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: #17474d;
  transform: scale(1.05);
}
