.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease; /* Fade-in animation */
}

.modalContent {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 16px; /* Smoother edges */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25); /* Subtle shadow */
  position: relative;
  font-family: 'Poppins', sans-serif;
  animation: slideUp 0.3s ease; /* Slide-up animation */
  width: 60%;
  max-width: 800px; /* Wider modal */
}

.modalTitle {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Add spacing below the title */
}

.priorityBadge {
  display: inline-block;
  padding: 4px 12px;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  margin-left: 20px; /* Add spacing between title and badge */
}


.description {
  margin: 20px 0;
}

.description strong {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.description p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.taskDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.detailItem {
  font-size: 16px;
  color: #555;
}

.detailItem strong {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.assignees {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assigneeAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.assigneeAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}
