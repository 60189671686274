
  
  .teamContainer {
    width: 100%;
    margin: 4px 0px;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font-size: small;
    border-radius: 8px;
    padding: 10px 0px 20px 0px;
    position: relative;
  }
  
  .tableContainer {
    margin-top: 0px !important;
    background-color: transparent !important;
    box-shadow: none;
  }

  .teamInfo {
    display: flex;
    height: 140px;
    gap: 50px;
  }
  
  .infoBlock {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 10px;
  }
  
  .infoBlock h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 200;
    line-height: 36px;
    color: #767676;
  }
  
  .infoBlock p {
    margin: 0;
    font-size: 18px;
    font-weight: 200;
    color: var(--primary-color);
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    gap: 10px;
    margin-top: -93px;
  }
  
  .button,
  .button1 {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: #767676;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .buttonIcon,
  .button1Icon {
    margin-right: 5px;
    color: #767676;
    font-size: 18px;
  }
  
  .button:hover,
  .button1:hover {
    background-color: var(--primary-color);
    color: blue;
  }
  
  .button:hover .buttonIcon,
  .button1:hover .button1Icon {
    color: blue;
  }
  .profileStack {
    display: flex;
    position: relative;
    margin-top: 2px; /* Adjusted margin to bring profile pictures just below "People in Team" */
  }
  
  .profilePicture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    margin-left: -10px; /* Overlapping effect */
    margin-bottom: 20px; 
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds smooth hover effect */
  }
  
  .profilePicture:hover {
    transform: scale(1.1); /* Slightly enlarges the picture on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  .peopleList {
    position: absolute;
    top: calc(100% + 10px); 
    right: 0; 
    overflow-y: scroll;
    height: 400px;
    width: 250px; 
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
  }
  
  .peopleList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .peopleList li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  .peopleList li:hover {
    background-color: #f5f5f5;
  }
  
  .listProfilePicture {
    width: 30px;  /* Reduced size */
    height: 30px; /* Reduced size */
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .taskForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px; /* Increased width for a larger form */
    max-width: 95%; /* Ensure it fits well on mobile */
    background-color: white;
    padding: 40px; /* More padding for a comfortable layout */
    border-radius: 16px; /* Softer border-radius for a modern look */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow for better emphasis */
    z-index: 1000;
    font-family: 'Poppins', sans-serif; /* Modern font */
  }
  
  .taskForm label {
    font-size: 14px;
    color: #333; /* Darker color for better readability */
    margin-bottom: 5px;
    display: block;
  }
  
  .taskForm input,
  .taskForm select,
  .taskForm textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .buttonContainerForm {
    display: flex;
    justify-content: space-between; /* One button left, one button right */
    margin-top: 20px; /* Add spacing above the buttons */
  }
  
  .taskForm button {
    padding: 12px;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 48%; /* Ensure buttons are wide enough */
    max-width: 200px; /* Limit button width */
  }

  
  .twoColumns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjusted gap for spacing */
  }
  
  .twoColumns div {
    flex: 1; /* Make both columns equal width */
    min-width: 45%; /* Ensure columns are responsive */
  }
  
  .fullWidth {
    width: 100%;
    margin-bottom: 20px; /* Add spacing between form rows */
  }
  