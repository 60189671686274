/* General container for the PDF viewer */
.previePDFcontainer {
    width: 80%;
    margin: auto;
    font-family: 'Roboto', Arial, sans-serif; /* Modern font */
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Subtle gradient */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main PDF content styling */
.pdfContainer {
    padding: 20px;
    background: #fff;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Main headings */
.heading {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
    color: #222;
}

.subheading {
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 500;
    color: #444;
}

/* BOQ section */
.boqSection {
    margin: 20px 0;
    background: #e3f2fd; /* Light blue background */
    border-radius: 8px;
    padding: 15px;
}

/* Horizontal line */
.horizontalLine {
    border: 0;
    height: 1px;
    background: #c0c0c0;
    margin: 10px 0;
}

/* Image Section */
.imageSection {
    text-align: center;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .houseImage {
    max-width: 99.5%; /* Ensures the image is not too wide */
    width: auto;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    object-fit: contain; /* Ensures the image scales without distortion */
  }
  

.image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Why Choose Us */
.whyChooseUs {
    margin: 20px 0;
    padding: 15px;
    border-radius: 8px;
}

.whyChooseUs h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #1a73e8;
}

/* Cost Breakup */
.costBreakup h4 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    color: #444;
}

.breakupTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-top: 10px;
}

.breakupTable th {
    background: #f3f3f3;
    border: 1px solid #ddd;
    padding: 16px; /* Added padding for content */
    text-align: left;
    font-weight: bold;
    color: #333;
}

.breakupTable td {
    border: 1px solid #ddd;
    padding: 30px 15px; /* Added padding for content */
    text-align: left;
    color: #555;
}

.breakupTable tfoot td {
    background-color: #f9f9f9;
    font-weight: bold;
    padding: 16px; /* Added padding for content */
}

/* Selected Items */
.selectedItemsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.selectedItemRow {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    padding: 15px;
    background: linear-gradient(135deg, #f9f9f9, #e0f7fa);
}

.selectedItemImageContainer {
    flex: 0 0 100px; /* Fixed width for images */
    text-align: center;
}

.selectedItemImage {
    width: 100px;
    height: auto;
    border-radius: 4px;
}

.selectedItemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.selectedItemDetails p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

/* Buttons */
.buttonContainer {
    text-align: center;
    margin-top: 20px;
}

.generateButton {
    background: linear-gradient(135deg, #4caf50, #2e7d32); /* Green gradient */
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.generateButton:hover {
    background: linear-gradient(135deg, #2e7d32, #1b5e20); /* Darker green on hover */
}

.generateButton:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Tables for Site Conditions */
.siteConditionsTable th {
    background: #f3f0ef;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding: 16px; /* Added padding */
}

.siteConditionsTable td {
    padding: 16px; /* Added padding */
    text-align: left;
    font-size: 14px;
    color: #444;
}

.conditionColumn {
    width: 30%;
}

.impactColumn {
    width: 25%;
}

.costImpactColumn {
    width: 25%;
}

.uomColumn {
    width: 10%;
    text-align: center;
}

.yesNoColumn {
    width: 10%;
    text-align: center;
    font-weight: bold;
}

.companyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .companyDetails {
    text-align: right;
  }
  
  .companyDetails h1 {
    font-size: 24px;
    margin: 0;
    color: #333;
  }
  
  .companyDetails p {
    font-size: 14px;
    margin: 2px 0;
    color: #555;
  }
  
  /* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .headerDetails {
    flex: 1;
  }
  
  .headerDetails p {
    font-size: 14px;
    margin: 5px 0;
    color: #333;
  }
  
  .headerDetails p strong {
    font-weight: bold;
    color: #000;
  }
  
  .date {
    text-align: right;
  }
  
  .date p {
    font-size: 14px;
    margin: 0;
    color: #333;
    font-weight: bold;
  }
  
  .siteInspectionTable th {
    background-color: #f4f4f4;
    color: #444;
  }